import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { approveRejectTransaction } from "../../../utils/services/transactions";
import { COMMON_STATUS } from "../../../utils/constants";
import { toast } from "react-toastify";

function AcceptRejectTransactionModal({ acceptRejectModal, setAcceptRejectModal, getData }) {
  const [loading, setLoading] = useState(false);
  const handleClose = () => {
    setAcceptRejectModal({ status: "", open: false, _id: "" });
  };

  const handleConfirm = async () => {
    setLoading(true);
    const payload = {
      transactionId: acceptRejectModal?._id,
      status: acceptRejectModal?.status === COMMON_STATUS.reject ? "rejected" : "completed" //["completed","rejected"]
    };
    const result = await approveRejectTransaction(payload);
    if (result?.type === "success") {
      toast.success(`Transaction ${acceptRejectModal?.status} successful`);
      getData();
      handleClose();
    } else {
      toast.error(
        result?.response?.data?.message ||
          result?.response?.data?.message?.message ||
          result?.response?.data?.message?.errors?.message
      );
    }
    setLoading(false);
  };
  return (
    <Modal show={acceptRejectModal?.open} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title className="text-capitalize">
          {acceptRejectModal?.status} Verification
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        Are you sure you want to {acceptRejectModal?.status} this transaction!
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose} disabled={loading}>
          Close
        </Button>
        <Button variant="primary" onClick={handleConfirm} disabled={loading}>
          Save Changes
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default AcceptRejectTransactionModal;
