import React from "react";
import { Container, Nav, NavDropdown, Navbar } from "react-bootstrap";
import { LOGIN } from "../../routes/page-urls";
import { useNavigate } from "react-router-dom";
import menuItems from "./Header/menu-items";

function Header() {
  const navigate = useNavigate();
  const handleLogout = () => {
    localStorage.removeItem("user");
    navigate(LOGIN);
  };

  const user = localStorage.getItem("user");

  return (
    <Navbar expand="lg" className="bg-body-tertiary">
      <Container>
        <Navbar.Brand href="#home">Spoflex</Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            {menuItems?.map((menu, index) =>
              menu?.subMenus ? (
                <NavDropdown title={menu?.title} id={`main-menu-${index}`} key={index}>
                  {menu?.subMenus?.map((subMenu, index) => (
                    <NavDropdown.Item key={index} href={subMenu?.subLink}>
                      {subMenu?.subTitle}
                    </NavDropdown.Item>
                  ))}
                </NavDropdown>
              ) : (
                <Nav.Link key={index} href={menu?.link}>
                  {menu?.title}
                </Nav.Link>
              )
            )}
          </Nav>
          {user && JSON.parse(user)?.token && (
            <Navbar.Text className="cursor-pointer" onClick={handleLogout}>
              Log Out
            </Navbar.Text>
          )}
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default Header;
