import React, { useEffect } from "react";
import { fetchAdminAccountsInfo } from "../../../../utils/services/accountInfo";
import { useState } from "react";
import AccountInfoEdit from "./AccountInfoEdit";

function AccountInfo() {
  const [loading, setLoading] = useState(false);
  const [accountData, setAccountData] = useState({});
  const [showEditForm, setShowEditForm] = useState(false);

  const getAccountInfo = async () => {
    setLoading(true);
    const result = await fetchAdminAccountsInfo();
    if (result?.length > 0) {
      setAccountData(result?.[result?.length - 1]);
    }
    setLoading(false);
  };

  useEffect(() => {
    getAccountInfo();
  }, []);

  if (loading) {
    return (
      <div className="d-flex justify-content-center align-items-center" style={{ height: "100vh" }}>
        <div className="spinner-border text-primary" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    );
  }

  return (
    <div className="container mt-5">
      <h3>Admin Account Info</h3>
      {showEditForm ? (
        <AccountInfoEdit
          data={accountData}
          setShowEditForm={setShowEditForm}
          getAccountInfo={getAccountInfo}
        />
      ) : accountData?._id ? (
        <div className="card">
          <div className="card-body">
            <h5 className="card-title">Account Information</h5>
            <p className="card-text">
              <strong>Account Number:</strong> {accountData.accountNo}
            </p>
            <p className="card-text">
              <strong>IBAN:</strong> {accountData.iBan}
            </p>
            <p className="card-text">
              <strong>Account Title:</strong> {accountData.title}
            </p>
            <p className="card-text">
              <strong>Bank Name:</strong> {accountData.bank}
            </p>
            <p className="card-text">
              <strong>Contact Info:</strong> {accountData.contact}
            </p>
            <button className="btn btn-primary" onClick={() => setShowEditForm(true)}>
              Edit
            </button>
          </div>
        </div>
      ) : (
        <button className="btn btn-primary">Add Account</button>
      )}
    </div>
  );
}

export default AccountInfo;
