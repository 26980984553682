import React from "react";
import "./App.css";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { PRIVATE_ROUTES, PUBLIC_ROUTES } from "./routes/routes";
import ProtectedRoute from "./components/ProtectedRoute";
import Layout from "./components/common/Layout";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function App() {
  return (
    <BrowserRouter>
      <ToastContainer />
      <Routes>
        <Route path="/" element={<Navigate to="/dashboard" />} />
        {PUBLIC_ROUTES.map((route, index) => (
          <Route key={index} path={route.url} element={<route.component />} />
        ))}
        {PRIVATE_ROUTES.map((route, index) => (
          <Route key={index} element={<ProtectedRoute />}>
            <Route
              key={index}
              path={route.url}
              element={
                <Layout>
                  <route.component />
                </Layout>
              }
            />
          </Route>
        ))}
        <Route
          path="*"
          element={
            <Layout>
              <h1>Page not found</h1>
            </Layout>
          }
        />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
