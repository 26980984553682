import { getRequest, putRequest } from "../axios-methods";

export const fetchAdminAccountsInfo = async () => {
  try {
    const result = await getRequest(`/account/admin`);
    return result;
  } catch (error) {
    return await error;
  }
};

export const updateAccountInfo = async (payload) => {
  try {
    const result = await putRequest(`/account/admin`, payload);
    return result;
  } catch (error) {
    return await error;
  }
};
