import React, { useEffect, useState } from "react";
import { Button, ButtonGroup, Dropdown, Spinner, Table } from "react-bootstrap";
import { getAllTransactions } from "../../../utils/services/transactions";
import { API_URL, COMMON_STATUS } from "../../../utils/constants";
import dayjs from "dayjs";
import AcceptRejectTransactionModal from "./AcceptRejectTransactionModal";
import Pagination from "../../../components/common/CustomPagination";
import { ACCOUNT_INFO } from "../../../routes/page-urls";
import { useNavigate } from "react-router-dom";

function Transactions() {
  const [status, setStatus] = useState(COMMON_STATUS.pending);
  const [transactions, setTransactions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [acceptRejectModal, setAcceptRejectModal] = useState({ state: "", open: false, _id: "" });

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const limit = 10;

  const getTransactions = async (filter, page) => {
    const newOffset = (page - 1) * limit;

    setLoading(true);
    const result = await getAllTransactions({ status: filter }, newOffset, limit);
    if (result?.type === "success") {
      setTransactions(result?.data);
      setTotalPages(Math.ceil(result?.totalCount / limit));
      setCurrentPage(page);
    }
    setLoading(false);
  };

  const goToPage = (pageNumber) => {
    getTransactions(status, pageNumber);
  };

  useEffect(() => {
    getTransactions(status, 1);
  }, [status]);

  const navigate = useNavigate();

  const downloadFile = (fileUrl) => {
    window.open(API_URL + "/" + fileUrl, "_blank");
  };

  return (
    <>
      <div className="d-flex justify-content-between">
        <h2 className="mb-5">Transactions</h2>
        <div>
          <Button onClick={() => navigate(ACCOUNT_INFO)}> Admin Account Info</Button>
          <p className="text-secondary">Click to view/update admin account info</p>
        </div>
      </div>

      <ButtonGroup className="mb-3">
        <Button onClick={() => setStatus("")} active={status === ""}>
          All
        </Button>
        <Button
          onClick={() => setStatus(COMMON_STATUS.pending)}
          active={status === COMMON_STATUS.pending}>
          Pending
        </Button>
        <Button
          onClick={() => setStatus(COMMON_STATUS.completed)}
          active={status === COMMON_STATUS.completed}>
          Completed
        </Button>
      </ButtonGroup>

      <Table response striped bordered hover>
        <thead>
          <tr>
            <th>Type</th>
            <th>FlexTokens</th>
            <th>Date</th>
            <th>Status</th>
            <th>Details</th>
            <th>File</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {loading ? (
            <Spinner animation="border" role="status"></Spinner>
          ) : transactions?.length > 0 ? (
            transactions.map((transaction, index) => (
              <tr key={index}>
                <td className="text-capitalize">{transaction.type}</td>
                <td>{transaction.amount}</td>
                <td>{dayjs(transaction.createdAt)?.format("DD-MM-YYYY hh:mm a")}</td>
                <td className="text-capitalize">{transaction?.status}</td>
                <td>
                  {transaction.type === COMMON_STATUS.transfer
                    ? `From: ${transaction.initiatedBy?.name} To: ${transaction.to}`
                    : `By: ${transaction.initiatedBy?.name}`}
                </td>
                <td>
                  {transaction?.fileUrl ? (
                    <button
                      className="btn btn-primary"
                      onClick={() => downloadFile(transaction?.fileUrl)}>
                      View
                    </button>
                  ) : (
                    ""
                  )}
                </td>
                <td>
                  {transaction?.status === COMMON_STATUS.pending &&
                    transaction?.type !== COMMON_STATUS.transfer && (
                      <Dropdown>
                        <Dropdown.Toggle variant="success" id="dropdown-basic">
                          Action
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                          <Dropdown.Item
                            onClick={() => {
                              setAcceptRejectModal({
                                status: "approve",
                                open: true,
                                _id: transaction?._id
                              });
                            }}>
                            Approve
                          </Dropdown.Item>
                          <Dropdown.Item
                            onClick={() => {
                              setAcceptRejectModal({
                                status: "reject",
                                open: true,
                                _id: transaction?._id
                              });
                            }}>
                            Reject
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    )}
                </td>
              </tr>
            ))
          ) : (
            "No data found"
          )}
        </tbody>
      </Table>

      <Pagination goToPage={goToPage} currentPage={currentPage} totalPages={totalPages} />

      {/* modal */}
      {acceptRejectModal?.open && (
        <AcceptRejectTransactionModal
          acceptRejectModal={acceptRejectModal}
          setAcceptRejectModal={setAcceptRejectModal}
          getData={() => getTransactions(status)}
        />
      )}
    </>
  );
}

export default Transactions;
