import { postRequest } from "../axios-methods";

/**
 * Get all transactions
 * @param {String} status ['pending' , 'complete']
 * @returns
 */
export const getAllTransactions = async (payload, offset, limit) => {
  try {
    const result = await postRequest(
      `/transaction/getpendingtransactions?skip=${offset}&limit=${limit}`,
      payload
    );
    return result;
  } catch (error) {
    return await error;
  }
};

/**
 * Update transaction status
 * @param {String} transactionId
 * @param {String} status ['rejected' , 'completed']
 * @returns
 */
export const approveRejectTransaction = async (payload) => {
  try {
    const result = await postRequest(`/transaction/updatetransactionstatus`, payload);
    return result;
  } catch (error) {
    return await error;
  }
};
