import axios from "axios";
import { API_URL } from "./constants";
/**
 *
 * @param {*} URL endpoint for API
 * @param {*} config Optional: Additional request headers if any by default will be empty, those set by axiosClient
 */
export function getRequest(URL) {
  let user = localStorage.getItem("user");
  if (user) {
    user = JSON.parse(user);
  }

  // Check if user and token exist before adding Authorization header
  const headers = {};
  if (user && user?.token) {
    headers["Authorization"] = `Bearer ${user.token}`;
  }

  return axios({
    method: "get",
    url: `${API_URL}${URL}`,
    headers: headers
  })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      // Handle errors here
      throw error; // Re-throw the error for further handling if necessary
    });
}

export async function postRequest(URL, payload) {
  let user = localStorage.getItem("user");
  if (user) {
    user = JSON.parse(user);
  }

  // Check if user and token exist before adding Authorization header
  const headers = {};
  if (user && user?.token) {
    headers["Authorization"] = `Bearer ${user.token}`;
  }

  return axios({
    method: "post",
    url: `${API_URL}${URL}`,
    data: payload,
    headers: headers
  })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      // Handle errors here
      throw error; // Re-throw the error for further handling if necessary
    });
}

export function patchRequest(URL, payload) {
  const user = localStorage.getItem("user");
  return axios.patch(`${API_URL}${URL}`, {
    headers: {
      Authorization: user?.token ? `Bearer ${user?.token}` : ""
    },
    data: payload
  });
}

export function putRequest(URL, payload) {
  let user = localStorage.getItem("user");
  if (user) {
    user = JSON.parse(user);
  }

  const headers = {};
  if (user && user?.token) {
    headers["Authorization"] = `Bearer ${user.token}`;
  }

  return axios({
    method: "put",
    url: `${API_URL}${URL}`,
    data: payload,
    headers: headers
  })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      // Handle errors here
      throw error; // Re-throw the error for further handling if necessary
    });
}

export function deleteRequest(URL) {
  const user = localStorage.getItem("user");
  return axios.delete(`${API_URL}${URL}`, {
    headers: {
      Authorization: user?.token ? `Bearer ${user?.token}` : ""
    }
  });
}
