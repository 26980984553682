export const API_URL =
  process.env.REACT_APP_API_URL || "https://spoflex-825672385627.herokuapp.com";

export const COMMON_STATUS = {
  pending: "pending",
  completed: "completed",
  topUp: "top-up",
  withdraw: "withdraw",
  transfer: "transfer",
  reject: "reject",
  approve: "approve"
};
