import React from "react";
import { useState } from "react";
import { updateAccountInfo } from "../../../../utils/services/accountInfo";

function AccountInfoEdit({ data, setShowEditForm, getAccountInfo }) {
  const [formData, setFormData] = useState(data);
  const [submitting, setSubmitting] = useState(false);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = async (e) => {
    try {
      setSubmitting(true);

      const payload = {
        title: formData?.title,
        accountNo: formData?.accountNo,
        bank: formData?.bank,
        iBan: formData?.iBan,
        contact: formData?.contact,
        accountId: formData?._id
      };
      e.preventDefault();

      const result = await updateAccountInfo(payload);

      if (result?.response?.data?.error) {
        alert(result?.response?.data?.error || "Error occurred while updating account info");
      } else {
        setSubmitting(false);
        setShowEditForm(false);
        getAccountInfo();
      }
    } catch (err) {
      alert("Error while updating account Info");
    } finally {
      setSubmitting(false);
    }
  };
  return (
    <div className="container mt-5">
      {/* form */}
      <div className="container mt-5">
        <div className="card">
          <div className="card-body">
            <h5 className="card-title">Account Information Form</h5>
            <form onSubmit={handleSubmit}>
              <div className="mb-3">
                <label htmlFor="accountNo" className="form-label">
                  Account Number
                </label>
                <input
                  type="text"
                  className={`form-control ${!formData.accountNo ? "is-invalid" : ""}`}
                  id="accountNo"
                  name="accountNo"
                  value={formData.accountNo}
                  onChange={handleChange}
                  required
                />
                {!formData.accountNo && (
                  <div className="invalid-feedback">Account Number is required.</div>
                )}
              </div>

              <div className="mb-3">
                <label htmlFor="iBan" className="form-label">
                  IBAN
                </label>
                <input
                  type="text"
                  className={`form-control ${!formData.iBan ? "is-invalid" : ""}`}
                  id="iBan"
                  name="iBan"
                  value={formData.iBan}
                  onChange={handleChange}
                  required
                />
                {!formData.iBan && <div className="invalid-feedback">IBAN Number is required.</div>}
              </div>

              <div className="mb-3">
                <label htmlFor="title" className="form-label">
                  Account Title
                </label>
                <input
                  type="text"
                  className={`form-control ${!formData.title ? "is-invalid" : ""}`}
                  id="title"
                  name="title"
                  value={formData.title}
                  onChange={handleChange}
                  required
                />
                {!formData.title && (
                  <div className="invalid-feedback">Account Title is required.</div>
                )}
              </div>

              <div className="mb-3">
                <label htmlFor="bank" className="form-label">
                  Bank Name
                </label>
                <input
                  type="text"
                  className={`form-control ${!formData.bank ? "is-invalid" : ""}`}
                  id="bank"
                  name="bank"
                  value={formData.bank}
                  onChange={handleChange}
                  required
                />
                {!formData.bank && <div className="invalid-feedback">Bank Name is required.</div>}
              </div>

              <div className="mb-3">
                <label htmlFor="contact" className="form-label">
                  Contact Info
                </label>
                <input
                  type="text"
                  className={`form-control ${!formData.contact ? "is-invalid" : ""}`}
                  id="contact"
                  name="contact"
                  value={formData.contact}
                  onChange={handleChange}
                  required
                />
                {!formData.contact && (
                  <div className="invalid-feedback">Contact Info is required.</div>
                )}
              </div>

              <div className="d-flex justify-content-between">
                <button
                  type="button"
                  className="btn btn-danger"
                  onClick={() => setShowEditForm(false)}
                  disabled={submitting}>
                  Cancel
                </button>{" "}
                <button type="submit" className="btn btn-primary" disabled={submitting}>
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AccountInfoEdit;
