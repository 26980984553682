import React from "react";
import { Pagination } from "react-bootstrap"; // Import Pagination component from React Bootstrap

function CustomPagination({ goToPage, currentPage, totalPages }) {
  return (
    <>
      {/* Pagination component from React Bootstrap */}
      <Pagination>
        {/* Previous page button */}
        <Pagination.Prev onClick={() => goToPage(currentPage - 1)} disabled={currentPage === 1} />
        {/* Page numbers */}
        {Array.from({ length: totalPages }, (_, i) => (
          <Pagination.Item key={i} active={i + 1 === currentPage} onClick={() => goToPage(i + 1)}>
            {i + 1}
          </Pagination.Item>
        ))}
        {/* Next page button */}
        <Pagination.Next
          onClick={() => goToPage(currentPage + 1)}
          disabled={currentPage === totalPages}
        />
      </Pagination>
    </>
  );
}

export default CustomPagination;
