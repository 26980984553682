import { postRequest } from "../axios-methods";

export const LOGIN_USER = async (payload) => {
  try {
    const result = await postRequest("/user/signin", payload);
    return result;
  } catch (error) {
    return await error;
  }
};
