import { SPONSORS, SPONSORS_PENDING, TRANSACTIONS } from "../../../routes/page-urls";

const menuItems = [
  {
    title: "Sponsors",
    subMenus: [
      {
        subTitle: "Active",
        subLink: SPONSORS
      },
      {
        subTitle: "Request Pending",
        subLink: SPONSORS_PENDING
      }
    ]
  },
  {
    title: "Transactions",
    link: TRANSACTIONS
  }
];
export default menuItems;
