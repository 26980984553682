import React, { useEffect, useState } from "react";
import { Form, Button, Alert } from "react-bootstrap";
import "./login.css";

import BackgroundImage from "../../assets/images/login-background.jpg";
import { LOGIN_USER } from "../../utils/services/user";
import { useNavigate } from "react-router-dom";

const Login = () => {
  const navigate = useNavigate();

  useEffect(() => {
    let user = localStorage.getItem("user");
    if (user && JSON.parse(user)?.token) {
      navigate("/dashboard");
    }
  }, [localStorage.getItem("user")]);

  const [inputUsername, setInputUsername] = useState("");
  const [inputPassword, setInputPassword] = useState("");

  const [show, setShow] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (event) => {
    setShow(false);
    event.preventDefault();
    setLoading(true);
    if (inputUsername && inputPassword) {
      const response = await LOGIN_USER({ email: inputUsername, password: inputPassword });
      if (response?.type === "success" && response?.userDetails?.role === "admin") {
        const user = {
          token: response?.token,
          ...response?.userDetails
        };

        localStorage.setItem("user", JSON.stringify(user));
      } else {
        setShow(true);
      }
      console.log(response);
    }

    setLoading(false);
  };

  return (
    <div className="sign-in__wrapper" style={{ backgroundImage: `url(${BackgroundImage})` }}>
      {/* Overlay */}
      <div className="sign-in__backdrop"></div>
      {/* Form */}
      <Form className="shadow p-4 bg-white rounded" onSubmit={handleSubmit}>
        {/* Header */}
        <div className="h4 mb-2 text-center">Sign In</div>
        {/* ALert */}
        {show ? (
          <Alert className="mb-2" variant="danger" onClose={() => setShow(false)} dismissible>
            Incorrect username or password. Login using admin credentials
          </Alert>
        ) : (
          <div />
        )}
        <Form.Group className="mb-2" controlId="username">
          <Form.Label>Username</Form.Label>
          <Form.Control
            type="text"
            value={inputUsername}
            placeholder="Username"
            onChange={(e) => setInputUsername(e.target.value)}
            required
          />
        </Form.Group>
        <Form.Group className="mb-2" controlId="password">
          <Form.Label>Password</Form.Label>
          <Form.Control
            type="password"
            value={inputPassword}
            placeholder="Password"
            onChange={(e) => setInputPassword(e.target.value)}
            required
          />
        </Form.Group>

        <Button className="w-100" variant="primary" type="submit" disabled={loading}>
          {loading ? "Logging In" : "Log In"}
        </Button>

        {/* <div className="d-grid justify-content-end">
          <Button className="text-muted px-0" variant="link" onClick={handlePassword}>
            Forgot password?
          </Button>
        </div> */}
      </Form>
    </div>
  );
};

export default Login;
