import React from "react";
import { LOGIN } from "../routes/page-urls";

import { Navigate, Outlet } from "react-router-dom";

function ProtectedRoute() {
  let user = localStorage.getItem("user");
  return user && JSON.parse(user)?.token ? <Outlet /> : <Navigate to={LOGIN} />;
}

export default ProtectedRoute;
